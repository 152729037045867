













import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';
import { connectorMethodsService } from '@/services/ConnectorMethodsService';

@Component({
  components: { Header, GeneralCrudList },
})
export default class FinancialCategories extends Vue {
  model: any = [
    { name: 'name', label: 'Nazwa', type: 'text' },
    { name: 'code', label: 'Konta (tag [kod] - wstawia w to miejsce kod sklepu)', type: 'text' },
    { name: 'verify', label: 'Konta weryfikacyjne (tag [kod] - wstawia w to miejsce kod sklepu)', type: 'text' },
    {
      name: 'type',
      label: 'Winien / Ma',
      type: 'select',
      options: [
        { id: 0, name: 'Ma (0)' },
        { id: 1, name: 'Winien (1)' },
        { id: 2, name: 'Wszystko' },
      ],
    },
    {
      name: 'method',
      label: 'Metoda API',
      type: 'select',
      options: [],
    },
  ];
  listOptions: any = [
    { field: 'name', label: 'Nazwa' },
    { field: 'code', label: 'Konta' },
    { field: 'verify', label: 'Konta weryfikacyjne' },
    { field: 'type', label: 'Winien / Ma', options: { 0: 'Ma', 1: 'Winien', 2: 'Wszystko' } },
    { field: 'method', label: 'Metoda API', options: {} },
  ];
  mounted() {
    connectorMethodsService
      .list()
      .then((data: any) => {
        this.listOptions[4].options = {};
        this.model[4].options = [];
        data.map((item: any) => {
          this.model[4].options.push(item);
          this.listOptions[4].options[item.id] = item.name;
        });
      })
      .catch(() => {});
  }
}
